import React from "react"
import { graphql } from "gatsby"
import DefaultLayout from '../layouts/DefaultLayout'
import BlogPost from "../components/blogPost/BlogPost"

const BlogPostTemplate = ({data}) => {
  const post = data.markdownRemark

  return (
    <DefaultLayout
      pageTitle={post.frontmatter.title}
    >
      <BlogPost post={post}/>
    </DefaultLayout>
  )
}

export default BlogPostTemplate

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        tags
        lang
      }
      fields {
        slug
        categories { id, name, url }
        tags { id, name, url }
      }
    }
  }
`
